"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Store = exports.UserValid = exports.StreamAction = exports.SocketAction = exports.AnalysisType = exports.StatType = exports.GameType = exports.ProductItem = void 0;
var ProductItem;
(function (ProductItem) {
    ProductItem["NoAds"] = "noads";
    ProductItem["Test"] = "test-non-consumable";
})(ProductItem || (exports.ProductItem = ProductItem = {}));
var GameType;
(function (GameType) {
    GameType[GameType["Battle"] = 0] = "Battle";
    GameType[GameType["BattleFriends"] = 1] = "BattleFriends";
    GameType[GameType["Speed"] = 2] = "Speed";
    GameType[GameType["Practice"] = 3] = "Practice";
    GameType[GameType["Tutorial"] = 4] = "Tutorial";
    GameType[GameType["Guess"] = 5] = "Guess";
})(GameType || (exports.GameType = GameType = {}));
var StatType;
(function (StatType) {
    StatType["Battle"] = "battle";
    StatType["Speed"] = "speed";
    StatType["SpeedDay"] = "speedday";
    StatType["Streak"] = "streak";
    StatType["Guess"] = "guess";
})(StatType || (exports.StatType = StatType = {}));
var AnalysisType;
(function (AnalysisType) {
    AnalysisType["Login"] = "logins";
    AnalysisType["Signup"] = "signups";
    AnalysisType["EntersBattle"] = "enters_battle";
    AnalysisType["EntersSpeed"] = "enters_speed";
    AnalysisType["EntersPractice"] = "enters_practice";
    AnalysisType["CompletesBattle"] = "completes_battle";
    AnalysisType["CompletesSpeed"] = "completes_speed";
    AnalysisType["EntersGuess"] = "enters_guess";
    AnalysisType["CompletesGuess"] = "completes_guess";
})(AnalysisType || (exports.AnalysisType = AnalysisType = {}));
var SocketAction;
(function (SocketAction) {
    SocketAction[SocketAction["Connect"] = 0] = "Connect";
    SocketAction[SocketAction["Disconnect"] = 1] = "Disconnect";
    SocketAction[SocketAction["Join"] = 2] = "Join";
    SocketAction[SocketAction["Joined"] = 3] = "Joined";
    SocketAction[SocketAction["CheckUser"] = 4] = "CheckUser";
    SocketAction[SocketAction["CheckedUser"] = 5] = "CheckedUser";
    SocketAction[SocketAction["ChangeUser"] = 6] = "ChangeUser";
    SocketAction[SocketAction["ChangeLanguage"] = 7] = "ChangeLanguage";
    SocketAction[SocketAction["ChangedPlayer"] = 8] = "ChangedPlayer";
    SocketAction[SocketAction["EnterRoom"] = 9] = "EnterRoom";
    SocketAction[SocketAction["LeaveRoom"] = 10] = "LeaveRoom";
    SocketAction[SocketAction["Room"] = 11] = "Room";
    SocketAction[SocketAction["RoomDone"] = 12] = "RoomDone";
    SocketAction[SocketAction["RoomStream"] = 13] = "RoomStream";
    SocketAction[SocketAction["Ready"] = 14] = "Ready";
    SocketAction[SocketAction["Draw"] = 15] = "Draw";
    SocketAction[SocketAction["Stat"] = 16] = "Stat";
    SocketAction[SocketAction["Buy"] = 17] = "Buy";
    SocketAction[SocketAction["ChangeLabel"] = 18] = "ChangeLabel";
    SocketAction[SocketAction["Skip"] = 19] = "Skip";
    SocketAction[SocketAction["Config"] = 20] = "Config";
    SocketAction[SocketAction["Reward"] = 21] = "Reward";
    SocketAction[SocketAction["Guess"] = 22] = "Guess";
})(SocketAction || (exports.SocketAction = SocketAction = {}));
var StreamAction;
(function (StreamAction) {
    StreamAction["PlayerLeave"] = "playerleave";
    StreamAction["PlayerEnter"] = "playerenter";
    StreamAction["Ready"] = "ready";
    StreamAction["Round"] = "round";
    StreamAction["RoundFinish"] = "roundfinish";
    StreamAction["RoundCancel"] = "roundcancel";
    StreamAction["Draw"] = "draw";
    StreamAction["Complete"] = "complete";
    StreamAction["RoomUpdate"] = "roomupdate";
    StreamAction["Guess"] = "guess";
})(StreamAction || (exports.StreamAction = StreamAction = {}));
var UserValid;
(function (UserValid) {
    UserValid[UserValid["Unavailable"] = 0] = "Unavailable";
    UserValid[UserValid["Available"] = 1] = "Available";
    UserValid[UserValid["Inappropriate"] = 2] = "Inappropriate";
})(UserValid || (exports.UserValid = UserValid = {}));
var Store;
(function (Store) {
    Store["Color"] = "color";
})(Store || (exports.Store = Store = {}));
