"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lvlForTerm = exports.levels = void 0;
exports.levels = [
    {
        level: 1,
        badge: 'fish',
        terms: ['fish']
    },
    {
        level: 2,
        badge: 'butterfly',
        terms: ['butterfly', 'rainbow']
    },
    {
        level: 3,
        badge: 'bee',
        terms: ['bee', 'flower', 'hexagon']
    },
    {
        level: 4,
        badge: 'scorpion',
        terms: ['scorpion', 'cactus', 'snake', 'ant']
    },
    {
        level: 5,
        badge: 'bat',
        terms: ['bat', 'mosquito', 'skull', 'spider', 'jail']
    },
    {
        level: 6,
        badge: 'squirrel',
        terms: ['squirrel', 'mouse', 'peanut', 'finger', 'piano', 'violin']
    },
    {
        level: 7,
        badge: 'hedgehog',
        terms: ['hedgehog', 'grass', 'snail', 'mushroom', 'leaf', 'rake', 'peas']
    },
    {
        level: 8,
        badge: 'parrot',
        terms: ['parrot', 'bird', 'feather', 'compass', 'map', 'sailboat', 'cloud', 'tornado']
    },
    {
        level: 9,
        badge: 'octopus',
        terms: ['octopus', 'pool', 'lobster', 'brain', 'calculator', 'eye', 'eyeglasses', 'book', 'smiley_face']
    },
    {
        level: 10,
        badge: 'rabbit',
        terms: ['rabbit', 'carrot', 'onion', 'potato', 'asparagus', 'string_bean', 'broccoli', 'basket', 'shovel', 'cookie']
    },
    {
        level: 11,
        badge: 'duck',
        terms: ['duck', 'swan', 'pond', 'frog', 'garden', 'garden_hose', 'swing_set', 'see_saw', 'lollipop', 'popsicle', 'ice_cream']
    },
    {
        level: 12,
        badge: 'owl',
        terms: ['owl', 'moon', 'star', 'lantern', 'light_bulb', 'flashlight', 'lightning', 'rain', 'umbrella', 'teapot', 'coffee_cup', 'cake']
    },
    {
        level: 13,
        badge: 'sea_turtle',
        terms: ['sea_turtle', 'ocean', 'submarine', 'speedboat', 'aircraft_carrier', 'cruise_ship', 'flip_flops', 'waterslide', 'diving_board', 'roller_coaster', 'birthday_cake', 'candle', 'hurricane']
    },
    {
        level: 14,
        badge: 'monkey',
        terms: ['monkey', 'banana', 'pear', 'apple', 'blueberry', 'blackberry', 'strawberry', 'watermelon', 'pineapple', 'bear', 'raccoon', 'line', 'squiggle', 'eraser']
    },
    {
        level: 15,
        badge: 'penguin',
        terms: ['penguin', 'snowflake', 'snowman', 'jacket', 'sweater', 't-shirt', 'pants', 'belt', 'shorts', 'sock', 'shoe', 'bowtie', 'triangle', 'octagon', 'square']
    },
    {
        level: 16,
        badge: 'sheep',
        terms: ['sheep', 'pig', 'dog', 'cat', 'cow', 'horse', 'windmill', 'barn', 'tractor', 'radio', 'stereo', 'drums', 'cello', 'harp', 'clarinet', 'guitar']
    },
    {
        level: 17,
        badge: 'flamingo',
        terms: ['flamingo', 'river', 'bridge', 'streetlight', 'traffic_light', 'stop_sign', 'tree', 'axe', 'saw', 'bulldozer', 'drill', 'pliers', 'screwdriver', 'bench', 'fence', 'paint_can', 'paintbrush']
    },
    {
        level: 18,
        badge: 'tiger',
        terms: ['tiger', 'ambulance', 'hospital', 'syringe', 'stitches', 'bandage', 'scissors', 'teddy-bear', 'suitcase', 'backpack', 'airplane', 'passport', 'parachute', 'The_Great_Wall_of_China', 'panda', 'yoga', 'postcard', 'envelope']
    },
    {
        level: 19,
        badge: 'kangaroo',
        terms: ['kangaroo', 'bush', 'matches', 'campfire', 'bucket', 'fire_hydrant', 'firetruck', 'truck', 'pickup_truck', 'bus', 'school_bus', 'van', 'car', 'motorbike', 'helmet', 'rollerskates', 'skateboard', 'bicycle', 'wheel']
    },
    {
        level: 20,
        badge: 'rhinoceros',
        terms: ['rhinoceros', 'police_car', 'donut', 'hot_dog', 'fork', 'knife', 'spoon', 'cup', 'mug', 'wine_glass', 'wine_bottle', 'bottlecap', 'cooler', 'power_outlet', 'television', 'remote_control', 'washing_machine', 'dresser', 'broom', 'door']
    },
    {
        level: 21,
        badge: 'dolphin',
        terms: ['dolphin', 'shark', 'mermaid', 'snorkel', 'canoe', 'beach', 'crab', 'palm_tree', 'lighthouse', 'house', 'mailbox', 'house_plant', 'fireplace', 'couch', 'table', 'bed', 'pillow', 'bathtub', 'hot_tub', 'toilet', 'underwear']
    },
    {
        level: 22,
        badge: 'camel',
        terms: ['camel', 'sun', 'hat', 'tent', 'sleeping_bag', 'chair', 'lighter', 'stove', 'frying_pan', 'oven', 'dishwasher', 'sink', 'toaster', 'bread', 'sandwich', 'pizza', 'hamburger', 'microwave', 'fan', 'ceiling_fan', 'floor_lamp', 'vase']
    },
    {
        level: 23,
        badge: 'giraffe',
        terms: ['giraffe', 'skyscraper', 'The_Eiffel_Tower', 'ladder', 'stairs', 'mountain', 'hot_air_balloon', 'animal_migration', 'zebra', 'crocodile', 'steak', 'lion', 'helicopter', 'headphones', 'microphone', 'telephone', 'megaphone', 'stethoscope', 'trombone', 'saxophone', 'trumpet', 'grapes', 'camouflage']
    },
    {
        level: 24,
        badge: 'elephant',
        terms: ['elephant', 'camera', 'laptop', 'computer', 'spreadsheet', 'keyboard', 'cell_phone', 'alarm_clock', 'clock', 'hourglass', 'wristwatch', 'bracelet', 'necklace', 'purse', 'lipstick', 'marker', 'pencil', 'crayon', 'The_Mona_Lisa', 'picture_frame', 'nail', 'hammer', 'hand', 'arm']
    },
    {
        level: 25,
        badge: 'whale',
        terms: ['whale', 'binoculars', 'circle', 'basketball', 'baseball_bat', 'baseball', 'hockey_stick', 'hockey_puck', 'tennis_racquet', 'golf_club', 'boomerang', 'dumbbell', 'soccer_ball', 'foot', 'toe', 'knee', 'leg', 'elbow', 'face', 'nose', 'ear', 'mouth', 'moustache', 'beard', 'goatee']
    },
    {
        level: 26,
        badge: 'dragon',
        terms: ['dragon', 'sword', 'anvil', 'cannon', 'rifle', 'crown', 'diamond', 'castle', 'church', 'chandelier', 'angel', 'tooth', 'toothpaste', 'toothbrush', 'flying_saucer', 'train', 'zigzag', 'paper_clip', 'key', 'calendar']
    }
];
function lvlForTerm(term) {
    for (let i = 0; i < exports.levels.length; i++) {
        if (exports.levels[i].terms.indexOf(term) !== -1) {
            return i;
        }
    }
    return null;
}
exports.lvlForTerm = lvlForTerm;
